<template>
  <div class="min-h-screen">
    <div class="bg-lightGray grid grid-cols-12 lg:grid-cols-12">
      <div class="col-span-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AuthLayout',
  }
</script>