<template>
  <div
    class="w-full h-screen flex items-center justify-center bg-lightGray">

    <div
      class="flex flex-col items-center justify-center bg-white rounded-xl  py-8 px-10">
      <div class="text-center mb-10">
        <h1 class="font-black text-3xl mb-2">Restore your password</h1>
      <p>put the code we sent you in your mail (check your spam box)</p>
      </div>
      
      <ValidationObserver ref="resetPasswordForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(resetPassword)" method="post"
          autocomplete="off">
          <div class="grid grid-cols-1 row-gap-4">
            <div>
              <ValidationProvider rules="required" vid="Code"
                name="Code" v-slot="{ errors }">
                <div class="flex items-center border-b py-2 w-full"
                  :class="errors.length ? 'border-b border-red-600' : ''">
                  <KeyIcon class="mr-2" />
                  <input v-model="form.token" type="text"
                    class="form-input focus:outline-none" placeholder="Code" />
                </div>
                <p class="text-red-700 text-sm mt-1" v-if="errors.length">
                  {{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div>

              <ValidationProvider rules="required|min:8" name="Password" vid="password" v-slot="{ errors}">
                <div class="flex items-center border-b py-2"
                  :class="errors.length ? 'border-b border-red-600' : ''">
                  <PasswordIcon class="mr-1"
                    :class="errors.length ? 'text-red-600' : ''" />
                  <input v-model="form.password"
                  class="form-input w-full"
                  :class="{'border-red-400': errors.length}"
                  placeholder="password"
                  type="password">
                  <HideIcon />
                </div>
                <p class="text-red-700 text-sm mt-1" v-if="errors.length">
                  {{ errors[0] }}</p>
              </ValidationProvider>

            </div>
            <div>
              <ValidationProvider rules="required|confirmed:password" vid="confirmation" name="Password confirmation" v-slot="{ errors }">
                <div class="flex items-center border-b py-2"
                  :class="errors.length ? 'border-b border-red-600' : ''">
                  <PasswordIcon class="mr-1"
                    :class="errors.length ? 'text-red-600' : ''" />
                  <input  v-model="form.confirm_password"
                  class="form-input w-full"
                  placeholder="confirm password"
                  :class="{'border-red-400': errors.length}"
                  type="password">
                  <HideIcon />
                </div>
                <p class="text-red-700 text-sm mt-1" v-if="errors.length">
                  {{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <div class="mt-4">
            <button type="submit" value="Enviar" class="w-full ">
              <Button class="py-3 px-10 mt-6 text-black text-sm"
                title="Restore" />
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>
import KeyIcon from "@/components/svgs/KeyIcon.vue";
import PasswordIcon from "@/components/svgs/PasswordIcon.vue";
import Button from "@/components/shared/Button.vue";
import HideIcon from "@/components/svgs/HideIcon.vue";
import { mapActions } from "vuex";
import { PASSWORD_RESET } from "@/store/types/actions";




export default {
  name: "ResetPassword",

  components: {
    KeyIcon,
    PasswordIcon,
    Button,
    HideIcon,
  },

  data() {
    return {
      loading: false,
      form: {
        token: null,
        password: null,
        confirm_password: null,
      },
      validationErrors: null,
      error: null,
    };
  },

  methods: {
    ...mapActions({
      resetPass: `auth/${PASSWORD_RESET}`,
    }),

    resetPassword: async function () {
      try {
        this.loading = true;
        await this.resetPass(this.form);
        this.$router.push({ name: "signIn" });
      } catch (ex) {
        console.log(ex);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>