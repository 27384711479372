<template>
  <button :class="colorButton" class="rounded-lg text-white w-full px-2 focus:outline-none focus:shadow-outline">
    <span class="flex-shrink-0">{{title}}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    title: String,
    colorButton: {
      type: String,
      default: "bg-customGreen",
    },
    activeColor: {
      type: String,
      default: 'bg-gray-400"',
    },
  },
};
</script>
