<template>
  <svg  width="24" height="24" viewBox="0 0 24 24" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0001 18.9999C11.1581 18.9999 10.3151 18.8219 9.49609 18.5049"
      stroke="#BDBDBD" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M20.882 12.468C18.99 15.967 15.495 19 12 19" stroke="#BDBDBD"
      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M19.0791 8.92102C19.7701 9.73002 20.3841 10.612 20.8821 11.533C21.0391 11.824 21.0391 12.177 20.8821 12.468"
      stroke="#BDBDBD" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M5 19L19 5" stroke="#BDBDBD" stroke-width="1.5"
      stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9.77309 14.227C8.54309 12.997 8.54309 11.002 9.77309 9.77199C11.0031 8.54199 12.9981 8.54199 14.2281 9.77199"
      stroke="#BDBDBD" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M17.0437 6.956C15.4967 5.759 13.7478 5 11.9998 5C8.50475 5 5.00975 8.033 3.11775 11.533C2.96075 11.824 2.96075 12.177 3.11775 12.468C4.06375 14.217 5.40975 15.849 6.95575 17.045"
      stroke="#BDBDBD" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
    name:"HideIcon"
};
</script>

<style>
</style>