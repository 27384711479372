var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-screen flex items-center justify-center bg-lightGray"},[_c('div',{staticClass:"flex flex-col items-center justify-center bg-white rounded-xl  py-8 px-10"},[_vm._m(0),_c('ValidationObserver',{ref:"resetPasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"method":"post","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('div',{staticClass:"grid grid-cols-1 row-gap-4"},[_c('div',[_c('ValidationProvider',{attrs:{"rules":"required","vid":"Code","name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center border-b py-2 w-full",class:errors.length ? 'border-b border-red-600' : ''},[_c('KeyIcon',{staticClass:"mr-2"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.token),expression:"form.token"}],staticClass:"form-input focus:outline-none",attrs:{"type":"text","placeholder":"Code"},domProps:{"value":(_vm.form.token)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "token", $event.target.value)}}})],1),(errors.length)?_c('p',{staticClass:"text-red-700 text-sm mt-1"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"rules":"required|min:8","name":"Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center border-b py-2",class:errors.length ? 'border-b border-red-600' : ''},[_c('PasswordIcon',{staticClass:"mr-1",class:errors.length ? 'text-red-600' : ''}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-input w-full",class:{'border-red-400': errors.length},attrs:{"placeholder":"password","type":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('HideIcon')],1),(errors.length)?_c('p',{staticClass:"text-red-700 text-sm mt-1"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"confirmation","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center border-b py-2",class:errors.length ? 'border-b border-red-600' : ''},[_c('PasswordIcon',{staticClass:"mr-1",class:errors.length ? 'text-red-600' : ''}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"form-input w-full",class:{'border-red-400': errors.length},attrs:{"placeholder":"confirm password","type":"password"},domProps:{"value":(_vm.form.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirm_password", $event.target.value)}}}),_c('HideIcon')],1),(errors.length)?_c('p',{staticClass:"text-red-700 text-sm mt-1"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"mt-4"},[_c('button',{staticClass:"w-full ",attrs:{"type":"submit","value":"Enviar"}},[_c('Button',{staticClass:"py-3 px-10 mt-6 text-black text-sm",attrs:{"title":"Restore"}})],1)])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mb-10"},[_c('h1',{staticClass:"font-black text-3xl mb-2"},[_vm._v("Restore your password")]),_c('p',[_vm._v("put the code we sent you in your mail (check your spam box)")])])}]

export { render, staticRenderFns }