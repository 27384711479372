<template>
  <div
    class="w-full h-screen flex items-center justify-center bg-lightGray">
    <div
      class="flex flex-col items-center justify-center bg-white rounded-xl py-8 px-10">
      <h1 class="font-black text-3xl">Forgot your password?</h1>

      <p class="mb-10 text-sm">
        just tell us which email address you signed up with.
      </p>
      <ValidationObserver ref="requestResetPasswordForm"
        v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(requestResetPassword)"
          method="post">
          <div>
            <ValidationProvider rules="required|email" vid="email" name="Email"
              v-slot="{ errors }" class="w-full">
              <div class="flex items-center border-b py-2 w-full"
                :class="errors.length ? 'border-b border-red-600' : ''">
                <EmailIcon class="mr-2" />
                <input v-model="form.email" type="email"
                  class="form-input focus:outline-none" placeholder="Email" />
              </div>

              <p v-show="errors.length"
                class="w-full text-red-600 mt-1 text-sm">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>

          <div class="mt-4">
            <button type="submit" value="Enviar" class="w-full">
              <Button class="py-3 px-10 mt-6 text-black text-sm"
                title="Submit" />
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EmailIcon from "@/components/svgs/EmailIcon.vue";
import Button from "@/components/shared/Button.vue";
import { PASSWORD_REQUEST_TOKEN } from "@/store/types/actions";

export default {
  name: "ForgotPassword",

  components: {
    EmailIcon,
    Button,
  },

  data: () => ({
    loading: false,
    form: {
      email: null,
    },
  }),

  methods: {
    ...mapActions({
      passwordRequestToken: `auth/${PASSWORD_REQUEST_TOKEN}`,
    }),

    requestResetPassword: async function () {
      try {
        this.loading = true;
        await this.passwordRequestToken(this.form);
        this.$router.push({ name: "ResetPassword" });
      } catch (ex) {
        console.log(ex);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>