<template>
  <div
    class="w-full h-screen flex items-center justify-center bg-lightGray">

    <div
      class="flex flex-col items-center justify-center bg-white rounded-xl  pb-8 px-10">
      <div class="mx-16 my-13 flex items-center">
        <DarkLogo />
      </div>

      <ValidationObserver class="w-full" ref="signinForm"
        v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(attemptSignIn)" method="post"
          class=" w-full">
          <div class="w-full">
            <ValidationProvider rules="required|email" vid="email" name="Email"
              v-slot="{ errors }" class="w-full">

              <div class="flex items-center border-b py-2 w-full"
                :class="errors.length ? 'border-b border-red-600' : ''">
                <EmailIcon class="mr-2" />
                <input v-model="form.email" type="email"
                  class="form-input focus:outline-none flex-1" placeholder="Email" />
              </div>

              <p v-show="errors.length"
                class="w-full text-red-600 mt-1 text-sm">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>

          <div class="mt-6 mb-4">
            <ValidationProvider rules="required" vid="password" name="Password"
              v-slot="{ errors }">
              <div class="flex items-center border-b py-2"
                :class="errors.length ? 'border-b border-red-600' : ''">
                <PasswordIcon class="mr-1"
                  :class="errors.length ? 'text-red-600' : ''" />
                <input v-model="form.password" type="password"
                  class="form-input focus:outline-none flex-1"
                  placeholder="Password" />
                <HideIcon />
              </div>

              <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
          <div>
            <router-link :to="{name: 'ForgotPassword'}">
            <span class="text-sm text-gray-800">Forgot password?</span>
            </router-link>
          </div>
          <div class="mt-4">
            <button type="submit" value="Enviar" class="w-full ">
              <Button class="py-3 px-10 mt-6 text-black text-sm"
                title="Log in" />
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>

  </div>

</template>

<script>
import DarkLogo from "@/components/svgs/DarkLogo.vue";
import EmailIcon from "@/components/svgs/EmailIcon.vue";
import PasswordIcon from "@/components/svgs/PasswordIcon.vue";
import Button from "@/components/shared/Button.vue";
import HideIcon from "@/components/svgs/HideIcon.vue";
import { ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";
import { SIGN_IN } from "@/store/types/actions";

extend("email", email);
extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "Login",

  components: {
    DarkLogo,
    EmailIcon,
    PasswordIcon,
    Button,
    ValidationProvider,
    ValidationObserver,
    HideIcon,
  },

  data() {
    return {
      form: {
        email: "",
        password: "",
      },

      loading: false,
    };
  },

  methods: {
    ...mapActions({
      signIn: `auth/${SIGN_IN}`,
    }),

    attemptSignIn() {
      this.loading = true;

      this.signIn(this.form)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.loading = false;

          if (error.response && error.response.data) {
            this.$refs.signinForm.setErrors(error.response.data.errors);
          }
          console.log("There was an error while trying to sign you in");
         
        });
    },
  },
};
</script>

<style>
</style>