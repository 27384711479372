<template>
  <svg width="168" height="67" viewBox="0 0 168 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M168 1.93933C158.43 -1.92666 147.537 2.69783 143.671 12.2684L136.887 29.0622L136.917 29.0733L131.317 42.9799L121.24 15.6114H121.2C120.712 14.4727 120.129 13.3825 119.452 12.3407C117.023 8.60443 113.567 5.73992 109.084 3.74722C104.6 1.75452 97.9371 0.758166 91.8967 0.758166H61.8193V66.1437H83.8636V50.0775H93.2978C99.3382 50.0775 104.6 49.0812 109.084 47.0885C109.938 46.7088 110.755 46.2974 111.535 45.8544L119.921 66.7055H141.778L162.328 15.6114H162.308L168 1.93933ZM91.8967 33.0773C94.8857 33.0773 97.1275 32.4235 98.6221 31.1157C100.117 29.7458 100.864 27.8465 100.864 25.4179C100.864 22.9892 100.117 21.1211 98.6221 19.8134C97.1275 18.4434 94.8857 17.7584 91.8967 17.7584H83.8636V33.0773H91.8967Z" fill="#00C400"/>
<rect x="0.643555" y="0.573975" width="50.3255" height="15.6885" rx="7.84425" fill="black"/>
<rect x="0.643555" y="50.9373" width="50.3255" height="15.6885" rx="7.84425" fill="black"/>
<rect x="17.1465" y="25.7555" width="33.822" height="15.6885" rx="7.84425" fill="black"/>
</svg>

</template>

<script>
export default {
name:"DarkLogo"
}
</script>
